<template>
  <div id="account-preferences-content">
    <div id="account-preferences-form">
      <div>
        <h1 class="account-preferences-header">
          {{ lang_stanza.customize_preference }}
        </h1>
      </div>
      <div>
        <p class="account-preferences-subtitle">
          {{ lang_stanza.customize_subtitle }}
        </p>
      </div>
      <grade-selection> </grade-selection>

      <!-- <b-form-group class="rectangle">
        <label for="disabilities-content" class="disabilities-content"
          >The site offers additional resources for students with
          disabilities</label
        >
        <b-form-checkbox
          id="disabilities-check"
          class="yes"
          :value="userPreferences.disabilityResources"
        >
          Yes, I am interested</b-form-checkbox
        >
      </b-form-group> -->

      <b-form-group class="reminders-checkbox">
        <label for="email-content">
          <span class="contact-text">
            <a
              href="https://support.texasoncourse.org/"
              class="contact-us"
              target="_blank"
              rel="noopener noreferrer"
              >Contact us </a
            >to edit your action plan email reminders.
          </span>
          <!-- <h3 class="contact-text">to edit your action plan email reminders.</h3> -->
        </label>
      </b-form-group>

      <!-- <div class="language-settings">Your Language preference is: <a style="font-weight: bold" href="#">{{getLanguage()}}</a></div> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import VueCookies from "vue-cookies";
import GradeSelection from "../components/GradeSelection";
import { router, sessionStorage } from "../_helpers";
export default {
  data() {
    return {
      userPreferences: {
        emailNotifications: false,
        disabilityResources: false,
      },
      lang_stanza_key: "profile",
      lang_stanza: {},
      lang_info: {},
    };
  },
  async created() {
    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      lang_code: this.lang_info["lang_code"],
      lang_stanza_key: this.lang_stanza_key,
    });

    let userSessionProfile = sessionStorage.decodeUserSession();
    if (!userSessionProfile || !userSessionProfile.sub) {
      router.push("`/${this.lang_info.lang_code}/login`");
    }
    await this.getUserGradePathwayList({
      oidc_id: userSessionProfile.sub,
      lang_code: this.lang_info.lang_code,
    });
    await this.getUserProfileByOidc(userSessionProfile.sub);
    this.userPreferences.emailNotifications = this.userProfile.is_email_sub;
    this.$forceUpdate();
  },
  components: {
    GradeSelection,
  },
  methods: {
    ...mapActions("preferences", ["getGrades", "getUserGradePathwayList"]),
    ...mapActions("account", ["getUserProfileByOidc"]),
    ...mapActions("language", ["getLangInfo", "getLangStanza"]),
    getLanguage() {
      let language = VueCookies.get("lang");
      if (language) {
        return language;
      } else {
        return "English";
      }
    },
    setEmailNotifications(e) {
      if (e.target.checked) {
        this.userPreferences.emailNotifications = true;
      } else {
        this.userPreferences.emailNotifications = false;
      }
    },
  },
  computed: {
    ...mapState("preferences", ["grades", "user_grade_pathway_list"]),
    ...mapState("account", ["userProfile"]),
  },
};
</script>

<style lang="scss">
@media (min-width: 769px) {
  h1.account-preferences-header {
    font-family: "Merriweather";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    color: #000000;
    margin-top: 48px !important;
    margin-bottom: 32px !important;
    text-align: left;
  }

  .account-preferences-subtitle {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-top: -16px;
    margin-bottom: 32px;
    text-align: left;
  }

  // .dropdown-list {
  //   width: 448px !important;
  // }

  .rectangle {
    height: 120px;
    width: 448px;
    border-radius: 6px;
    background-color: #f5f7fa;
    padding: 16px 22px;
    margin-bottom: -1px;
    margin-top: 18px;
  }

  .disabilities-content {
    color: #3a3a3a;
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
  }

  label.custom-control-label::before {
    display: none !important;
  }

  label.custom-control-label::after {
    display: none !important;
  }

  input[type="checkbox" i]#disabilities-check {
    width: 24px;
    height: 24px;
    opacity: 0.65;
  }

  input[type="checkbox" i]#email-check {
    width: 24px;
    height: 24px;
    opacity: 0.65;
  }

  .check-email {
    margin-top: 10px;
  }

  label[for="email-content"] {
    color: #3a3a3a;
    font-family: Lato;
    font-size: 24px;
    text-align: center;
    padding-top: 15px;
    margin-top: -100px;
  }

  .contact-us {
    color: #277ae6;
    font-weight: 700;
  }

  .contact-text {
    font-size: 16px;
  }

  .yes .custom-control-label {
    margin-left: 16px;
    margin-bottom: 2px;
  }

  .check-email .custom-control-label {
    margin-left: 8px;
    margin-top: -5px;
  }

  .reminders-checkbox {
    border-radius: 6px;
    // background-color: #f5f7fa;
    padding: 14px 0px 0px 11px;
    margin-top: -34px;
    max-width: 448px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center !important;
    margin-bottom: -45px !important;
  }

  .language-settings {
    text-align: left;
    margin-top: 33px;
    font-family: Lato;
    font-size: 18px;
  }

  label {
    color: #333333;
    font-family: Lato;
    font-size: 14px;
  }
}

@media (min-width: 500px) and (max-width: 768px) {
  .account-preferences-header {
    font-family: "Merriweather";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    color: #000000;
    margin-top: 48px !important;
    margin-bottom: 32px !important;
    text-align: left;
  }

  .account-preferences-subtitle {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-top: -16px;
    margin-bottom: 32px;
    text-align: left;
  }

  label.custom-control-label::before {
    display: none !important;
  }

  label.custom-control-label::after {
    display: none !important;
  }

  .contact-us {
    color: #277ae6;
    font-weight: 700;
    text-align: center;
  }

  .contact-text {
    font-size: 16px;
    text-align: center !important;
  }
  .reminders-checkbox {
    border-radius: 6px;
    // background-color: #f5f7fa;
    padding: 14px 8px 0px 11px;
    margin-top: -24px;
    max-width: 448px;
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: -192px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center !important;
    margin-bottom: -45px !important;
  }

  .email-content {
    font-size: 30px;
  }
}

@media (min-width: 360px) and (max-width: 499px) {
  h1.account-preferences-header {
    font-family: "Merriweather";
    font-style: normal;
    font-weight: 700;
    font-size: 24px !important;
    line-height: 36px;
    color: #000000;
    text-align: left;
    margin-top: 34px;
    margin-bottom: 16px;
  }

  .account-preferences-subtitle {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    text-align: left;
    margin-bottom: 16px;
  }

  .contact-text {
    font-size: 16px;
    line-height: 24px;
  }

  .contact-us {
    color: #277ae6;
    font-weight: 700;
  }
}

// @media (max-width: 375px) {
//   .account-preferences-header {
//     color: #000000;
//     font-family: Lato;
//     font-size: 32px;
//     line-height: 48px;
//     font-weight: 700;
//     font-style: normal;
//     margin-top: 72px;
//     margin-bottom: 25px;
//     text-align: left;
//   }

//   .account-preferences-subtitle {
//     font-size: 16px;
//     line-height: 24px;
//     font-style: normal;
//     text-align: left;
//   }

//   label.custom-control-label::before {
//     display: none !important;
//   }

//   label.custom-control-label::after {
//     display: none !important;
//   }

//   .contact-us {
//     color: #277ae6;
//     font-weight: 700;
//     text-align: center;
//   }

//   .contact-text {
//     font-size: 16px;
//     text-align: center !important;
//   }

//   .reminders-checkbox {
//     border-radius: 6px;
//     // background-color: #f5f7fa;
//     padding: 14px 8px 0px 11px;
//     margin-top: -24px;
//     max-width: 448px;
//     margin-right: 30px;
//     margin-left: 30px;
//     text-align: center !important;
//     font-family: "Open Sans";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 16px;
//     line-height: 24px;
//     text-align: center !important;
//     margin-bottom: -45px !important;
//   }

//   .email-content {
//     font-size: 30px;
//   }
// }

// @media (max-width: 375px) {
//   .dropdown-list {
//     position: absolute;
//     z-index: 2;
//     left: -15px;
//     max-width: 110%;
//     max-height: 500px;
//     margin-top: -30px;
//     overflow-y: auto;
//     background: #ffffff;
//     box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
//       0 4px 6px -2px rgba(0, 0, 0, 0.05);
//   }
// }
</style>
